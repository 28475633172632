import { Config } from './types';

const configs: Config[] = [
  {
    platform: 'tezos',
    network: 'mainnet',
    displayName: 'Tezos Mainnet',
    url: 'https://conseil-prod.cryptonomic-infra.tech:443',
    apiKey: 'galleon',
    entities: ['blocks', 'operations', 'accounts', 'bakers', 'governance'],
    hiddenEntities: ['originated_account_maps', 'big_maps', 'big_map_contents'],
    nodeUrl: 'https://tezos-prod.cryptonomic-infra.tech:443'
  },
  {
    platform: 'tezos',
    network: 'delphinet',
    displayName: 'Tezos Delphinet',
    url: 'https://conseil-dev.cryptonomic-infra.tech:443',
    apiKey: 'galleon',
    entities: ['blocks', 'operations', 'accounts', 'bakers', 'governance'],
    hiddenEntities: ['originated_account_maps', 'big_maps', 'big_map_contents'],
    nodeUrl: 'https://tezos-dev.cryptonomic-infra.tech:443'
  }
]

export default configs; 
