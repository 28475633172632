export const SET_HOURLY_TRANSACTION = 'SET_HOURLY_TRANSACTION';
export const SET_HOURLY_TRANSACTION_LOADING = 'SET_HOURLY_TRANSACTION_LOADING'; 
export const SET_HOURLY_VOLUME = 'SET_HOURLY_VOLUME';
export const SET_HOURLY_VOLUME_LOADING ='SET_HOURLY_VOLUME_LOADING';

export const SET_HOURLY_GAS = 'SET_HOURLY_GAS';
export const SET_HOURLY_GAS_LOADING ='SET_HOURLY_GAS_LOADING';

export const SET_HOURLY_FEE = 'SET_HOURLY_FEE';
export const SET_HOURLY_FEE_LOADING ='SET_HOURLY_FEE_LOADING';

export const SET_DAILY_ACTIVATION = 'SET_DAILY_ACTIVATION';
export const SET_DAILY_ACTIVATION_LOADING ='SET_DAILY_ACTIVATION_LOADING';

export const SET_DAILY_ORIGINATION = 'SET_DAILY_ORIGINATION';
export const SET_DAILY_ORIGINATION_LOADING ='SET_DAILY_ORIGINATION_LOADING';

export const SET_HOURLY_TRANSACTION_QUERY = 'SET_HOURLY_TRANSACTION_QUERY';
export const SET_HOURLY_GAS_QUERY = 'SET_HOURLY_GAS_QUERY';
export const SET_HOURLY_FEE_QUERY = 'SET_HOURLY_FEE_QUERY';
export const SET_DAILY_ORIGINATION_QUERY = 'SET_DAILY_ORIGINATION_QUERY';
export const SET_HOURLY_VOLUME_QUERY = 'SET_HOURLY_VOLUME_QUERY';
export const SET_DAILY_ACTIVATION_QUERY = 'SET_DAILY_ACTIVATION_QUERY';